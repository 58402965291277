import axios from 'axios';

export default {
  namespaced: true,

  state: {
    customers: [],
    customer: {},
  },

  getters: {
    customers(state) {
      return state.customers;
    },
    customer(state) {
      return state.customer;
    },
    billingAddress(state) {
      if (state.customer && state.customer.addresses) {
        return state.customer.addresses.filter((address) => address.type === 'B');
      }
      return [];
    },
    shippingAddresses(state) {
      if (state.customer && state.customer.addresses) {
        return state.customer.addresses.filter((address) => address.type === 'S');
      }
      return [];
    },
    currency(state) {
      if (state.customer) {
        return state.customer.currency;
      }
      return [];
    },
    isExtraCee(state) {
      if (state.customer) {
        return state.customer.extraCee;
      }
      return [];
    },
  },

  mutations: {
    setCustomers(state, value) {
      state.customers = value;
    },
    setCustomer(state, value) {
      state.customer = value;
    },
    setCustomerAddresses(state, value) {
      state.customer.addresses = value;
    },
  },

  actions: {
    async getCustomers({ commit }) {
      const { data } = await axios.get('/api/customers');
      commit('setCustomers', data);
    },
    async searchCustomers({ commit }, text) {
      const { data } = await axios.get(`/api/customers?text=${text}`);
      commit('setCustomers', data);
      return data;
    },
    async getCustomer({ commit }, id) {
      const { data } = await axios.get(`/api/customers/${id}`);
      commit('setCustomer', data);
      return data;
    },
    async postCustomer({ commit }, customer) {
      const { data } = await axios.post('/api/customers', customer);
      commit('setCustomer', data);
    },
    async putCustomer({ commit }, { id, ...remaining}) { // eslint-disable-line
      const { data } = await axios.put(`/api/customers/${id}`, remaining);
      return data;
    },
    async getCustomerAddresses({ commit }, id) {
      const { data } = await axios.get(`/api/customers/${id}/addresses`);
      commit('setCustomerAddresses', data);
    },
    async postCustomerAddresses({ dispatch }, { customerId, ...remaining }) {
      const { data } = await axios.post(`/api/customers/${customerId}/addresses`, remaining);
      dispatch('getCustomerAddresses', customerId);
      return data;
    },
    async putCustomerAddress({ dispatch }, { customerId, id, ...remaining }) {
      const { data } = await axios.put(`/api/customers/${customerId}/addresses/${id}`, remaining);
      dispatch('getCustomerAddresses', customerId);
      return data;
    },
    async deleteCustomerAddress({ dispatch }, { customerId, addressId }) {
      const { data } = await axios.delete(`/api/customers/${customerId}/addresses/${addressId}`);
      dispatch('getCustomerAddresses', customerId);
      return data;
    },
    replaceCustomer({ commit }, customer) {
      commit('setCustomer', customer);
    },
    replaceCustomerAddresses({ commit }, addresses) {
      commit('setCustomerAddresses', addresses);
    },
    async getCustomerOrders({ commit }, id) { // eslint-disable-line
      const { data } = await axios.get(`/api/customer/${id}/orders`);
      return data;
    },
  },

};
