import axios from 'axios';

export default {
  namespaced: true,

  state: {
    orders: [],
    order: {},
  },

  getters: {
    orders(state) {
      return state.orders;
    },
    order(state) {
      return state.order;
    },
  },

  mutations: {
    setOrders(state, value) {
      state.orders = value;
    },
    setOrder(state, value) {
      state.order = value;
    },
  },

  actions: {
    async getOrders({ commit }) {
      const { data } = await axios.get('/api/orders');
      commit('setOrders', data);
    },
    async getOrder({ commit }, id) {
      const { data } = await axios.get(`/api/orders/${id}`);
      commit('setOrder', data);
      return data;
    },
    async postOrder({ commit }, order) {
      const { data } = await axios.post('/api/orders', order);
      commit('setOrder', data);
      return data;
    },
    async putOrder({ commit }, { id, ...remaining }) {
      const { data } = await axios.put(`/api/orders/${id}`, remaining);
      commit('setOrder', data);
    },
    async postOrderRows({ commit }, { id, ...orderRows }) { // eslint-disable-line
      const { data } = await axios.post(`/api/order/${id}/rows`, { rows: orderRows });
      return data;
    },
  },

};
