import { createApp } from 'vue';
import Toast, { POSITION, useToast } from 'vue-toastification';
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css';
import axios from 'axios';
import InstantSearch from 'vue-instantsearch/vue3/es';
import 'instantsearch.css/themes/satellite.css';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import './css/app.css';

const toast = useToast();

axios.defaults.withCredentials = true;

const toastOptions = {
  icon: false,
  position: POSITION.BOTTOM_LEFT,
};

store.dispatch('shops/getShop').then(() => {});

const app = createApp(App)
  .use(i18n)
  .use(store)
  .use(router)
  .use(Toast, toastOptions)
  .use(InstantSearch)
  .mount('#app');

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status < 308) return; // ignore redirects
    if (error.response.status === 404) { // notify not found and continue
      toast.error(app.$t('apiErrors.notFound'));
      return;
    }
    if (error.response.status !== 401) { // manage generic errors
      if (error.response.data && error.response.data.message) {
        toast.error(app.$t('apiErrors.generic'));
      }
      store.dispatch('auth/clearCredentials');
      router.push('/');
      return;
    }
    throw error;
  },
);
