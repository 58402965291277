<!-- eslint-disable vuejs-accessibility/anchor-has-content -->
<template>
   <div
   class="bg-gray-700 w-screen text-white flex place-self-center py-4 px-8 space-x-4 items-center"
   v-if="authenticated && isSuperAdmin">
    <div class="w-14 h-14">
      <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 113.39 113.39">
        <g id="Layer_1-2" data-name="Layer 1">
          <g>
            <path d="m.42,48.01l11.88,44.34c1.14,4.26,4.47,7.59,8.74,8.74l44.34,11.88c4.26,1.14,8.81-.08,11.93-3.2l32.46-32.46c3.12-3.12,4.34-7.67,3.2-11.93l-11.88-44.34c-1.14-4.26-4.47-7.59-8.74-8.74L48.01.42c-4.26-1.14-8.81.08-11.93,3.2L3.62,36.08c-3.12,3.12-4.34,7.67-3.2,11.93Z" fill="#fbb03b"/>
            <path d="m47.19,13.74c.65,0,1.3.09,1.94.26l35.47,9.5c2.57.69,4.6,2.72,5.29,5.29l9.5,35.47c.69,2.57-.05,5.34-1.94,7.23l-25.97,25.97c-1.41,1.41-3.29,2.19-5.29,2.19-.65,0-1.3-.09-1.94-.26l-35.47-9.5c-2.57-.69-4.6-2.72-5.29-5.29l-9.5-35.47c-.69-2.57.05-5.34,1.94-7.23l25.97-25.97c1.41-1.41,3.29-2.19,5.29-2.19m0-2.4c-2.59,0-5.11,1.02-6.99,2.89l-25.97,25.97c-2.5,2.5-3.47,6.14-2.56,9.55l9.5,35.47c.91,3.41,3.58,6.07,6.99,6.99l35.47,9.5c.85.23,1.7.34,2.56.34,2.59,0,5.11-1.02,6.99-2.89l25.97-25.97c2.5-2.5,3.47-6.14,2.56-9.55l-9.5-35.47c-.91-3.41-3.58-6.07-6.99-6.99l-35.47-9.5c-.85-.23-1.7-.34-2.56-.34h0Z" fill="#fff"/>
            <g>
              <path d="m24.87,53.64l5.59-1.5c3.5-.94,6.41.49,7.28,3.73s-.94,4.86-1.25,5.1c.38,0,3.89.25,4.98,4.32.88,3.3-.77,6.2-4.75,7.27l-6.33,1.7-5.52-20.62Zm8.35,7.74c2.8-.75,3.53-2.84,2.93-5.08s-2.63-3.46-5.46-2.7l-3.92,1.05,2.11,7.89,4.33-1.16Zm2.98,9.81c3.62-.97,4.22-3.44,3.68-5.47s-2.36-3.98-5.54-3.12l-5.07,1.36,2.27,8.48,4.65-1.25Z"/>
              <path d="m56.43,60.08c.07.26.08.29.14.63l-11.58,3.1c.66,2,3.12,4.47,6.69,3.51,2.06-.55,3.37-2.13,3.98-3.53l1.31.78c-.88,1.91-2.68,3.66-4.89,4.25-4.01,1.07-7.76-1.39-8.79-5.22s.73-7.77,4.73-8.84c3.56-.95,7.3,1.2,8.4,5.32Zm-1.8-.37c-.47-1.52-2.66-4.4-6.2-3.45-3.74,1-4.09,4.63-3.76,6.12l9.95-2.67Z"/>
              <path d="m71.69,55.99c.07.26.08.29.14.63l-11.58,3.1c.66,2,3.12,4.47,6.69,3.51,2.06-.55,3.37-2.13,3.98-3.53l1.31.78c-.88,1.91-2.68,3.66-4.89,4.25-4.01,1.07-7.76-1.39-8.79-5.22s.73-7.77,4.73-8.84c3.56-.95,7.3,1.2,8.4,5.32Zm-1.8-.37c-.47-1.52-2.66-4.4-6.2-3.45-3.74,1-4.09,4.63-3.76,6.12l9.95-2.67Z"/>
              <path d="m81.4,50.01c.55-1.25,1.83-3.65,1.22-5.92-.66-2.47-2.68-3.99-5.65-3.19-3.59.96-3.63,4.82-3.63,4.82l-1.62-.17s.11-4.89,4.85-6.16c3.71-.99,6.73.85,7.65,4.26.66,2.44-.75,5.35-1.02,6.08l-4.33,10,9.28-2.49.38,1.41-12.31,3.3,5.19-11.96Z" fill="#fff"/>
            </g>
          </g>
        </g>
      </svg>
    </div>
      <p>Bee2 is another fine product of The Nonsense Factory</p>
    </div>
  <div class="bg-main w-screen text-white flex flex-col place-self-center rounded-t-2xl" v-else>
    <div class="grid grid-cols-2 gap-x-4 p-6 lg:p-10 pb-3 lg:pb-3">
      <div>
        <img alt="logo" :src="footerContents.whiteLogo" class="w-full sm:w-1/2 lg:w-1/3" />
        <div class="flex flex-col my-3">
          <div class="flex space-x-2 items-center">
            <HomeIcon />
            <p class="footerText">
              {{ $t('address') }}
            </p>
          </div>
          <div class="flex space-x-2 items-center">
            <EmailIcon />
            <p class="footerText">
              {{ $t('companyEmail') }}
            </p>
          </div>
          <div class="flex space-x-2 items-center">
            <PhoneIcon />
            <p class="footerText">
              {{ $t('phoneNumber') }}
            </p>
          </div>
        </div>
        <div class="flex space-x-2">
          <a :href="footerContents.facebookUrl" target="_blank">
            <facebook-icon />
          </a>
          <a :href="footerContents.instagramUrl" target="_blank">
            <instagram-icon />
          </a>
          <a :href="footerContents.xTwitterUrl" target="_blank">
            <XTwitter-icon />
          </a>
          <a :href="footerContents.youtubeUrl" target="_blank">
            <youtube-icon />
          </a>
        </div>
      </div>
    </div>
    <div class="border-t border-white p-6 lg:p-10 pt-2 lg:pt-2">
      <p class="footerText">{{ $t('copyright') }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import appVariables from '@/config/appVariables.json';
import FacebookIcon from '@/components/graphic/FacebookIcon.vue';
import YoutubeIcon from '@/components/graphic/YoutubeIcon.vue';
import InstagramIcon from '@/components/graphic/InstagramIcon.vue';
import XTwitterIcon from '@/components/graphic/XTwitterIcon.vue';
import HomeIcon from '@/components/graphic/HomeIcon.vue';
import PhoneIcon from '@/components/graphic/PhoneIcon.vue';
import EmailIcon from '@/components/graphic/EmailIcon.vue';

export default {
  components: {
    FacebookIcon,
    YoutubeIcon,
    InstagramIcon,
    XTwitterIcon,
    HomeIcon,
    PhoneIcon,
    EmailIcon,
  },
  data() {
    return {
      footerContents: appVariables,
    };
  },
  computed: {
    ...mapGetters('auth', ['authenticated', 'isSuperAdmin']),
  },
};
</script>

<style>
.footerText {
  @apply text-sm lg:text-base
}
</style>
