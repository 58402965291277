/* eslint-disable max-len */
import axios from 'axios';
import { checkProductQuantity } from '@/utils/cart-service'; // eslint-disable-line
import store from '../store'; // eslint-disable-line

export default {
  getAllProductCategories() {
    return axios.get('/api/product-categories');
  },

  getProductCategoriesForHomepage() {
    return axios.get(`/api/product-categories?in_homepage=${true}`);
  },

  getHomepageProductsForCategory(slug) {
    return axios.get(`/api/home-management/products/product-category/${slug}`);
  },

  getProductBrandsForHomepage() {
    return axios.get(`/api/product-brands?in_homepage=${true}`);
  },

  getAllProductBrands() {
    return axios.get('/api/product-brands');
  },

  getProductBrand(slug) {
    return axios.get(`/api/product-brands/${slug}`);
  },

  getProductsForBrandAndCategory(brandId, category) {
    return axios.get(`/api/home-management/products/product-category/${category}?brand=${brandId}`);
  },

  getStrings() {
    return axios.get('/api/products?category=STR');
  },
};

export function getVatRate(id) {
  const [vatId] = store.getters['vats/vatById'](id);
  return id ? vatId.rate : 0;
}

export function getProduct(sku) {
  return axios.get(`/api/products/${sku}`);
}

export function addProductToCart(givenProduct, qty) {
  const product = givenProduct;
  const vatId = !givenProduct.product_id ? givenProduct.vatId : givenProduct.product.vat_id;
  const vatRate = 100 + getVatRate(vatId);
  product.vatRate = vatRate;
  product.quantity = qty;
  product.isVariant = !!givenProduct.product_id;
  const quantity = checkProductQuantity(product);
  const totalPrice = product.isDiscounted ? product.discountedPrice * quantity : product.price * quantity;
  const totalPriceWithVat = (totalPrice * vatRate) / 100;
  const singlePriceWithVat = product.isDiscounted ? (product.discountedPrice * vatRate) / 100 : (product.price * vatRate) / 100;
  const vat = totalPriceWithVat - totalPrice;

  const productToAdd = {
    ...product,
    quantity,
    totalPrice,
    totalPriceWithVat,
    singlePriceWithVat,
    vat,
  };

  const roundedTotalFromSinglePrice = Math.round(singlePriceWithVat) * quantity;
  const roundedTotalPriceWithVat = Math.floor(totalPriceWithVat);
  productToAdd.roundedTotalPriceWithVat = roundedTotalPriceWithVat;

  if (roundedTotalPriceWithVat - roundedTotalFromSinglePrice === 1) {
    productToAdd.roundedTotalPriceWithVat = roundedTotalFromSinglePrice;
  }

  store.dispatch('cart/addProduct', productToAdd);
}

export function sendProductRequestByMail(id, quantity) {
  return axios.post(`/api/products/${id}/request?quantity=${quantity}`);
}
