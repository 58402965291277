<template>
  <div :class="classes" class="my-6 hidden lg:flex justify-center w-full">
    <div class="w-5/6 xl:w-3/4 flex justify-between rounded-lg shadow-md bg-white dark:bg-slate-700 px-6">
      <div class="flex space-x-4 xl:space-x-8 2xl:space-x-12 items-center">
        <router-link to="/" class="w-1/12 h-12 flex items-center overflow-hidden dark:bg-white/90 p-2 rounded-md">
          <img alt="logo" :src="dashboardContents.logo" />
        </router-link>
        <div v-if="!isSuperAdmin" class="flex space-x-4 xl:space-x-8 2xl:space-x-12 items-center text-sm 2xl:text-base">
          <base-navbar-link
            v-for="link in dashboardContents.links" :key="link.label"
            :label="link.label"
            :hasDropdown="link.hasDropdown"
            :dropdowns="link.links"
          />
        </div>
      </div>
      <navbar-buttons />
    </div>
  </div>

  <div class="block lg:hidden">
    <div class="flex w-full p-4">
      <button @click="openCloseNavbar()" class="z-50 lg:hidden absolute fill-black dark:fill-white">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 17" class="w-5">
          <defs></defs>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <rect class="cls-1" x="1" y="1" width="18" height="1" rx="0.38" />
              <rect class="cls-1" x="1" y="15" width="29" height="1" rx="0.38" />
              <rect class="cls-1" x="1" y="8" width="23" height="1" rx="0.38" />
            </g>
          </g>
        </svg>
      </button>
      <navbar-buttons class="w-full justify-end"/>
    </div>

    <div class="w-full flex absolute justify-between">
      <transition name="slide-fade">
        <div class="flex absolute w-screen p-14 h-full md:w-1/2 lg:w-1/4 shadow-inner
          space-y-5 z-40 bg-white dark:bg-slate-800" v-if="showNavbar">
          <div :class="mobileClasses">
            <router-link to="/" class="w-1/2" @click="openCloseNavbar()">
              <img alt="logo" :src="dashboardContents.logo" class="w-full"/>
            </router-link>
            <div v-if="isSuperAdmin" class="flex flex-col space-y-2">
              <base-navbar-link
                label="home"
                link-to="/"
                @click="openCloseNavbar()"
              />
              <base-navbar-link
                label="Prodotti"
                hasDropdown
                :dropdowns="productsLinks"
                @select="openCloseNavbar()"
                class="w-fit"
              />
              <base-navbar-link
                label="Clienti"
                hasDropdown
                :dropdowns="customerLinks"
                @select="openCloseNavbar()"
                class="w-fit"
              />
              <base-navbar-link
                label="Ordini"
                hasDropdown
                :dropdowns="orderLinks"
                @select="openCloseNavbar()"
                class="w-fit"
              />
              <base-navbar-link
                label="Generale"
                hasDropdown
                :dropdowns="genericLinks"
                @select="openCloseNavbar()"
                class="w-fit"
              />
            </div>
            <div v-if="!isSuperAdmin" class="flex flex-col space-y-2 text-base">
              <base-navbar-link
                v-for="link in dashboardContents.links" :key="link.label"
                :label="link.label"
                :hasDropdown="link.hasDropdown"
                :dropdowns="link.links"
                @select="openCloseNavbar()"
              />
            </div>
          </div>
        </div>
      </transition>
      <button
        class="w-screen z-30 h-screen backdrop-blur-md lg:hidden cursor-default flex place-self-end"
        :class="showNavbar ? 'opacity-100 transition-opacity duration-500 ease-in-out' : 'opacity-0 hidden'"
        @click="openCloseNavbar()"
      >
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseNavbarLink from '@/components/base/BaseNavbarLink.vue';
import appVariables from '@/config/appVariables.json';
import NavbarButtons from './NavbarButtons.vue';

export default {
  components: {
    BaseNavbarLink,
    NavbarButtons,
  },
  data() {
    return {
      caseClass: {
        uppercase: 'uppercase',
        lowercase: 'lowercase',
        capitalize: 'capitalize',
      },
      weightClass: {
        light: 'font-light',
        normal: 'font-normal',
        bold: 'font-bold',
      },
      directionClass: {
        horizontal: 'flex flex-row space-x-4',
        vertical: 'flex flex-col space-y-2',
      },
      showNavbar: false,
      dashboardContents: appVariables,
    };
  },
  props: {
    fontCase: {
      type: String,
      default: 'uppercase',
    },
    direction: {
      type: String,
      default: 'horizontal',
    },
    mobileDirection: {
      type: String,
      default: 'vertical',
    },
    fontWeight: {
      type: String,
      default: 'light',
    },
    elementsColor: {
      type: String,
      default: 'text-black dark:text-white',
    },
    slideCart: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('auth', ['authenticated', 'isSuperAdmin']),
    classes() {
      // eslint-disable-next-line max-len
      return `${this.caseClass[this.fontCase]} ${this.directionClass[this.direction]} ${this.weightClass[this.fontWeight]} ${this.elementsColor}`;
    },
    mobileClasses() {
      // eslint-disable-next-line max-len
      return `${this.caseClass[this.fontCase]} ${this.directionClass[this.mobileDirection]} ${this.weightClass[this.fontWeight]} ${this.elementsColor}`;
    },
  },
  methods: {
    openCloseNavbar() {
      this.showNavbar = !this.showNavbar;
      this.stopScroll();
    },
    stopScroll() {
      if (this.showNavbar === true) {
        const element = document.body;
        element.classList.add('overflow-hidden');
        return;
      }
      const element = document.body;
      element.classList.remove('overflow-hidden');
    },
  },
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.5s ease-in-out;
}

.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}
</style>
