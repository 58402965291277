<template>
  <div class="flex items-center space-x-1 group lg:py-6"
    @mouseover="openDropdown()" @mouseleave="closeDropdown()"
    @focus="openDropdown()" @click="openDropdown()"
    @keydown="openDropdown()" @blur="closeDropdown()"
  >
    <div class="relative inline-block">
      <router-link
      v-if="!hasDropdown"
      :class="[elementsColor, hasDropdown ? '' : 'hover:text-secondary']"
      :to="linkTo"
      >
        {{ translateKey(label) }}
      </router-link>
      <p
      class="text-sm 2xl:text-base font-normal cursor-default"
      v-else
      >
          {{ translateKey(label) }}
      </p>
        <transition name="slide-fade-dropdown">
        <div v-show="hasDropdown && showDropdown"
          class="absolute w-fit whitespace-nowrap rounded-md shadow-xl px-5 py-4 text-left flex flex-col
          bg-white dark:bg-slate-700 z-50 space-y-1 lg:mt-6 border-t-4 border-secondary"
        >
          <div v-for="dropdown in dropdowns" :key="dropdown">
            <router-link v-if="dropdown.type === 'string'" :to="dropdown.link" @click="$emit('select')"
            :class="elementsColor" class="text-sm 2xl:text-base">
              {{ translateKey(dropdown.label) }}
            </router-link>

            <div v-if="dropdown.type === 'list'">
              <button @click="showList = !showList" class="flex items-center" :class="elementsColor">
                <p class="text-sm 2xl:text-base">{{ translateKey(dropdown.label) }}</p>
                <dropdown-arrow :class="showList ? 'rotate-180 duration-300' : ''"/>
              </button>
              <div class="flex flex-col pl-4" v-if="showList">
                <router-link v-for="value in dropdown.values" :key="value" :to="value.link" :class="elementsColor"
                class="text-sm 2xl:text-base">
                  {{ value.label.replaceAll('_', ' ') }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <dropdown-arrow v-if="hasDropdown" />
  </div>
</template>

<script>
import DropdownArrow from '@/components/graphic/DropdownArrow.vue';

export default {
  components: { DropdownArrow },
  data() {
    return {
      showDropdown: false,
      showList: false,
    };
  },
  props: {
    label: {
      type: String,
    },
    hasDropdown: {
      type: Boolean,
      default: false,
    },
    linkTo: {
      type: String,
      default: '',
    },
    dropdowns: {
      type: Array,
    },
    elementsColor: {
      type: String,
      default: 'text-gray-800 dark:text-white hover:text-secondary font-thin dark:hover:text-secondary',
    },
  },
  methods: {
    openDropdown() {
      this.showDropdown = true;
    },
    closeDropdown() {
      this.showDropdown = false;
    },
    openCloseDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    translateKey(key) {
      const path = 'frontoffice.navbar';
      const translated = this.$t(`${path}.${key}`);
      if (translated.includes(path)) {
        return key;
      }
      return translated;
    },
  },
};
</script>

<style>
.arrow:after {
  content: '\25be';
  display: inline-block;
  vertical-align: bottom;
}
.slide-fade-dropdown-enter-active {
  transition: all 0.5s ease-in-out;
}

.slide-fade-dropdown-leave-active {
  transition: all 0.5s cubic-bezier(0.1, 5, 8, 0.1);
}

.slide-fade-dropdown-enter-from,
.slide-fade-dropdown-leave-to {
  transform: translateY(-1vh);
  opacity: 0;
}
</style>
